@media (min-width: 300px) and (max-width: 1000px) {
  .footer-section-about {
    background: #f8a61a !important;
    font-size: 0.8em !important;
    color: #0b2432;
    padding-top: 3%;
    padding-bottom: 3%;
  }

  .support-team-container {
    margin-top: 5%;
  }

  .about_page_social_media_container {
    width: 100%;
    margin-top: 2%;
  }

  .about_page_linkedin_image,
  .about_page_twitter_image,
  .about_page_fb_image {
    width: 10%;
    padding: 2%;
  }

  .team-card-list .team-individual-container .team-individual-info .status {
    font-size: 1em;
    color: #7d7b7b;
  }

  .avatar {
    width: 5em;
    height: 5em;
  }

  .team-card-list .team-individual-container {
    display: flex;
    flex-direction: column;
    // padding: 10px 0;
    align-items: center;
    padding: 5%;
    margin: 5%;
  }

  .team-card-list .team-individual-container .team-individual-info .username,
  .team-card-list .team-individual-container .team-individual-info a.username {
    color: #f8a61a;
    font-size: 1.15em;
    margin-bottom: 5px;
    font-weight: 600;
  }

  .about_tequip_founder_image {
    width: 50%;
    margin-top: 3%;
    animation: 1.5s ease-out 0s 1 slideInFromLeft;
    pointer-events: none;
  }

  .about_tequip_parent_section__container {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .about-tequip-text,
  .support-team-tequip-text,
  .about-tequip-founder-title,
  .values-tequip-text {
    font-size: 1.5em;

    color: #0b2432 !important;
  }

  .about-tequip-founder-title {
    margin-top: 3%;
  }
  .about-background-image {
    width: 100% !important;
  }
  .about-tequip-description {
    color: #0b2432;
    font-size: 1.15em;
    padding: 3%;
    animation: 1.5s ease-out 0s 1 slideInFromLeft;
  }
  .about-tequip-founder-designation {
    color: #0b2432;
    font-size: 1.15em;
    width: 100%;
  }

  .values_tequip_container {
    display: flex;
    flex-direction: column !important;
    flex-wrap: nowrap;
    justify-content: center;
  }

  .values_tequip_image_text {
    width: 200px;
    text-shadow: 0 0 1px #000;
    position: absolute;
    font-size: 1.1em;
    height: 170px;
    opacity: 0;
    background: white;
    overflow: hidden;
  }
}

.values-tequip-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-background-image {
  width: 100% !important;
}

.company-title-text {
  font-family: sans-serif;
  font-size: 1.5em !important;
}

.about-page-text-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 5%;
}

.about-tequip-text:after,
.support-team-tequip-text:after,
.values-tequip-text:after {
  content: "";
  position: absolute;
  left: 50%;
  display: block;
  height: 0.25em;
  width: 6em;
  background: #f8a61a;
  transform: translateX(-50%);
  margin-top: 0.5%;
}
.values-tequip-text:after {
  background: white;
  margin-top: 0.5%;
}

@media (min-width: 1000px) and (max-width: 2000px) {
  .footer-section-about {
    background: #f8a61a !important;
    font-size: 1em !important;
    color: #0b2432;
    padding-top: 3%;
  }

  .about_page_social_media_container {
    width: 100%;
    padding: 3%;
  }

  .about_page_linkedin_image,
  .about_page_twitter_image,
  .about_page_fb_image {
    width: 3%;
    cursor: pointer;
    padding: 1%;
  }

  .team-card-list .team-individual-container .team-individual-info .status {
    font-size: 1.15em;
    color: #7d7b7b;
  }
  .avatar {
    width: 8em;
    height: 8em;
  }

  .team-card-list .team-individual-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1%;
    margin: 5%;
  }

  .team-card-list .team-individual-container .team-individual-info .username,
  .team-card-list .team-individual-container .team-individual-info a.username {
    color: #f8a61a;
    font-size: 1.25em;
    margin-bottom: 5px;
    font-weight: 600;
  }

  .about_tequip_founder_image {
    width: 20%;
    margin-top: 3%;
    animation: 1.5s ease-out 0s 1 slideInFromLeft;
    pointer-events: none;
  }

  .about-tequip-text,
  .support-team-tequip-text,
  .about-tequip-founder-title,
  .values-tequip-text {
    color: #0b2432 !important;

    font-size: 2em;
  }

  .about-tequip-description {
    color: #0b2432;
    font-size: 1.25em;
    padding: 3%;
    animation: 1.5s ease-out 0s 1 slideInFromLeft;
  }
  .about-tequip-founder-designation {
    color: #0b2432;
    font-size: 1.25em;
    width: 100%;
  }

  .values_tequip_container,
  .values-tequip-text-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
  }

  .values_tequip_image_text {
    width: 200px;
    text-shadow: 0 0 1px #000;
    position: absolute;
    font-size: 1.1em;
    height: 170px;
    opacity: 0;
    background: white;
    overflow: hidden;
  }

  .values_container:hover .values_tequip_image_text {
    opacity: 1;
    transition: all 0.2s ease-in-out;
  }

  .values_container:hover .values_tequip_image {
    opacity: 0.3;
  }
}

.about_tequip_founder_details_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.about_city_square_text,
.about_city_square_text:visited {
  font-style: italic;
  color: #f8a61a !important;
}

.about_city_square_text:hover {
  cursor: pointer;
}

.necessity-quote {
  color: #f8a61a;
  font-style: italic;
}
.figure-caption {
  bottom: -100%;
  height: 75px;
  opacity: 1;
  background: rgba(245, 160, 30, 0.7);
  text-shadow: 0 0 1px #000;
}

.values_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.our-values-tequip {
  background-color: #f8a61a;
}


.team-card-list .team-individual-container .team-individual-info {
  flex: 1;
}

.avatar {
  position: relative;
  display: inline-block;
}

.avatar-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.rounded-circle {
  border-radius: 50% !important;
}
