

.header-main-container {
    display: flex;
    height: 60px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #f8a61a;
}

.header-sub-container {
    display: flex;
    flex-direction: row;
    width: 100%;

    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    align-items: center;
}

.website-title {
    font-size: 1.5em;
    color: #f8a61a;
}

.website-title-container {
    width: 100%;   
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-text {
    font-size: 1em;
    color: red!important;
}
 
@media (min-width: 700px) and (max-width: 1024px) {  

    .available-news-card {
        width: 50%!important;
    }
    .website-title-container {
        width: 60%!important;
    }

    .header-text {
        font-size: 1.25em;
    }
}

@media (min-width: 1024px)  {


    .header-text {
        font-size: 1.25em;
    }
} 


@media (min-width: 400px) and (max-width: 700px) {  

    .website-title {
        font-size: 1.25em!important;
    }

    .website-title-container {
        width: 50%!important;
    }


}


@media (min-width: 300px) and (max-width: 400px) {  
    .header-text {
        font-size: 0.9em!important;
    }
    .website-title {
        font-size: 1.15em!important;
    }
    .website-title-container {
        height: 40px!important;
        width: 35%!important;
    }

}

.icon--menu-open {
    background-image: url(data:image/svg+xml;charset%3DUS-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2243.2%22%20height%3D%2243.2%22%20viewBox%3D%220%200%2043.2%2043.2%22%3E%3Cpath%20fill%3D%22%23fff%22%20stroke%3D%22%23000%22%20stroke-width%3D%225%22%20stroke-miterlimit%3D%2210%22%20d%3D%22M0%205.8h43.2M0%2037.4h43.2M0%2021.6h43.2%22%2F%3E%3C%2Fsvg%3E);
    // background-image: url('../../../assets/nav_pill.png');
    background-repeat: no-repeat;
}
.toggle__icon {
    vertical-align: middle;
    width: 20px;
    height: 20px;
}
.icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    background-size: contain;
}



.header__toggle {
    position: fixed;
    top: 0;
    right: 0;
    display: block;
    z-index: 10;
}
@media (min-width: 1000px) and (max-width: 2000px) {

    .toggle {
        display: block;
        margin: 10px;
        padding: 1.2em;
        line-height: 1;
        border: 0;
        background: rgba(255,255,255,0.6);
        border-radius: 0;
        transition: none;
        background-color: #faa71b;
        
    }
}

@media (min-width: 300px) and (max-width: 1000px) {

    .toggle {
        display: block;
        margin: 0;
        padding: 1.2em;
        line-height: 1;
        border: 0;
        background: rgba(255,255,255,0.6);
        border-radius: 0;
        transition: none;
        

    }
}


.header__nav {
    visibility: hidden;
    opacity: 0;
    transform: translateX(100%);
    transition: all .15s ease-out;
}

.header__nav_open {

    visibility: visible;
    transform: translateX(0);
    opacity: 1;

}

.nav-container {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    background: #0b2432;
}

.nav {
    margin-top: 3.2em;
}
.list--icons, .list--headings, .list--download, .nav, .list--unset {
    list-style: none;
    margin: 0;
}

.header_nav_link {
    font-family: "Montserrat",Corbel,"Lucida Grande","Lucida Sans Unicode","Lucida Sans","DejaVu Sans","Bitstream Vera Sans","Liberation Sans",Verdana,"Verdana Ref",sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .125em;
    position: relative;
    display: block;
    padding: 1em;
    color: #f8a61a;
    background: #fff;
    border-top: 1px solid #F1F1F1;
    opacity: 1; transform: translateX(100px)
}

ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
} 

.icon--menu-close {
    background-image: url(data:image/svg+xml;charset%3DUS-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2243.2%22%20height%3D%2243.2%22%20viewBox%3D%220%200%2043.2%2043.2%22%3E%3Cpath%20fill%3D%22%23fff%22%20stroke%3D%22%23333%22%20stroke-width%3D%225%22%20stroke-miterlimit%3D%2210%22%20d%3D%22M3%203l36.9%2036.9m0-36.9L3%2039.9%22%2F%3E%3C%2Fsvg%3E);
    background-repeat: no-repeat;
}

@media (max-width: 900px) {

    .side_nav_item {
        display: block;
        margin-bottom: 0;
        margin-left: -20%!important;
    }

}
@media (width: 768px) {
    .side_nav_item {
        display: block;
        margin-bottom: 0;
        margin-left: -2%!important;

    }
}

@media (min-width: 900px) and (max-width: 2000px) {
    .side_nav_item {
        display: block;
        margin-bottom: 0;
    }
    
}

@media (max-width: 1000px) {  

    .is-active-nav {
    
        overflow: hidden;
        height: 120vh;
    }

}
@media (min-width: 1000px) and (max-width: 2000px) {  

    .is-active-nav {
    
        overflow: hidden;
        height: 120vh;
        width: 300px!important;
    }

}

.is-active-nav .header__nav {
    visibility: visible;
    transform: translateX(0);
    opacity: 1;
}
.is-active-nav .nav-container {
    overflow-y: scroll;
}

  .nav--invert {
    display: flex;
    align-items: self-start;
    flex-direction: column;
    margin-top: 50%;
    animation: 1s ease-out 0s 1 slideInFromLeft;
  }

  @media (min-width: 300px) and (max-width: 1000px) {  

    .tequip-logo-header-open {
        position: absolute;
        display: block;
        width: 28%;
        z-index: 10;
        left: 0;
        margin-left: 2%;
  }
  }

  @media (min-width: 1000px) and (max-width: 2000px) {  
      
    .tequip-logo-header-open {
        position: absolute;
        display: block;
        width: 10%;
        z-index: 10;
        left: 0;
  }
  .tequip-logo-header-open:hover {
    cursor: pointer;
  }
  }

 
  .tequip-logo-header-closed {
    position: absolute;
    top: 1rem;
    left: 1rem;
    display: block;
    width: 10%;
    height: 7%;
    z-index: 10;
    visibility: hidden!important;
}

@media (min-width: 1000px) and (max-width: 2000px) {  
    .tequip-header-container {
        display: flex;
        height: 75px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #0b2433;
    }


}

@media (min-width: 300px) and (max-width: 1000px) {  

.tequip-header-container {
    display: flex;
    height: 52px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #0b2433;
}

}


.header_nav_link , .header_nav_link:visited {
    color: #f8a61a!important;
}