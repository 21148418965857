@media (max-width: 1000px) {
    .footer-section-products {
      font-size: 0.8em !important;
      color: #0b2432;
      padding-top: 3%;
      padding-bottom: 3%;
    }
    .city_square_video {
      width: 70%;
      margin-bottom: 5%;
    }
  
    .city_square_stats_sub_container {
      display: flex;
      flex-direction: row;
      justify-items: center;
      align-items: center;
      padding: 5%;
    }
    .products_illstn {
      width: 80%;
      animation: 1.5s ease-out 0s 1 slideInFromLeft;
    }
    .our-products-tequip-text {
      font-size: 1.5em;
    }
  
    .city_square_app_desc_text {
      color: #0b2432;
  
      align-items: flex-end;
      margin-top: 3%;
      font-size: 0.75em;
      text-align: right;
      padding: 5%;
      animation: 1.5s ease-out 0s 1 slideInFromLeft;
    }
    .city_square_app_desc_title {
      font-size: 1em;
  
      color: #0b2432;
    }
  
    .city_square_stat_heading {
      font-size: 2em;
      color: #0b2432;
    }
  
    .city_square_stat_content {
      font-size: 1em;
      color: #f8a61a;
    }
    .city-square-problemstatement-container .city_square_app_desc_title {
      margin-top: 5%;
      color: white;
    }
    .follow-us-image {
      width: 50%;
    }
  }
  
  @media (min-width: 1000px) and (max-width: 2000px) {
    .footer-section-products {
      font-size: 1em !important;
      color: #0b2432;
      padding-top: 3%;
    }
    .follow-us-image {
      width: 15%;
    }
  
    .city_square_video {
      width: 70%;
      margin-bottom: 5%;
      height: 500px;
      margin-top: 3%;
    }
    .city-square-problemstatement-container .city_square_app_desc_title {
      margin-top: 2%;
      color: white;
    }
  
    .city_square_stats_sub_container {
      display: flex;
      flex-direction: row;
      justify-items: center;
      align-items: center;
    }
  
    .products_illstn {
      width: 65%;
      animation: 1.5s ease-out 0s 1 slideInFromLeft;
    }
    .our-products-tequip-text {
      font-size: 2em;
    }
  
    .city_square_app_desc_title {
      font-size: 2em;
  
      color: #0b2432;
    }
  
    .city_square_app_desc_text {
      color: #0b2432;
  
      align-items: flex-end;
      margin-top: 3%;
      font-size: 1.25em;
      text-align: right;
      padding: 2%;
    }
    .city_square_stat_heading {
      font-size: 3.5em;
      color: #0b2432;
    }
  
    .city_square_stat_content {
      font-size: 2em;
      color: #f8a61a;
    }
  }
  
  .our-products-tequip-text:after {
    content: "";
    position: absolute;
    left: 50%;
    display: block;
    height: 0.25em;
    width: 6em;
    background: #f8a61a;
    transform: translateX(-50%);
    margin-top: 0.5%;
  }
  
  .city_square_app_container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }
  
  .city_square_illstn {
    width: 50%;
    animation: 1.5s ease-out 0s 1 slideInFromLeft;
  }
  
  .city_square_app_desc_container {
    display: flex;
    flex-direction: column;
    background-color: #f8a61a;
  }
  
  .city-square-problemstatement-container {
    display: flex;
    background-color: #0b2432;
    flex-direction: column;
    justify-items: center;
    align-items: center;
  }
  
  .city-square-problemstatement-container .city_square_app_desc_text {
    color: #f8a61a;
    margin-top: 1% !important;
    text-align: center;
  }
  
  .city_square_stats_container {
    width: 100%;
  }
  
  .city_square_downloads_container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  
  .city_square_downloads_container {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
  }
  
  .follow-us-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2%;
    align-items: center;
    width: 100%;
  }
  
  .insta_username {
    font-size: 1.5em;
  }
