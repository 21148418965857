.in-the-news-news-card-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-bottom: 10%;
}

.news_platform_text {
  font-size: 1.5em;
  font-style: oblique;
  color: revert;
}

.news_platform_text_container {
  margin-top: 12%;
  margin-bottom: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.in-the-news-main-container {
  overflow: hidden;
}
