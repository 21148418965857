.our-services-container,
.our_services_parent_section__container {
  background-color: #0b2432;
}
.home-background-image {
  width: 100% !important;
  scroll-behavior: smooth;
}
.home-background-image-container {
  scroll-behavior: smooth;
}

@media (width: 320px) {
  .footer-section {
    background: #0b2432 !important;
    font-size: 0.5em !important;
    color: white;
  }
  .home-page-text-container {
    position: absolute;
    top: 60px;
    right: 1px;
    display: flex;
    flex-direction: column;
    text-align: right;
    margin-left: 35%;
    margin-right: 2%;
    animation: 1.5s ease-out 0s 1 slideInFromLeft;
  }
  .why-tequip-description {
    color: #0b2432;
    font-size: 1em;
    font-weight: 600;
    padding: 3%;
    text-align: center;
    animation: 1.5s ease-out 0s 1 slideInFromLeft;
  }
}

.our_services_parent_section__container,
.why_tequip_parent_section__container {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.why_tequip_parent_section__container {
  margin-bottom: 2%;
}

.why-tequip-description {
  color: #0b2432;
  font-size: 1.25em;
  font-weight: 600;
  padding: 3%;
  text-align: center;
  animation: 1.5s ease-out 0s 1 slideInFromLeft;
}

.why-tequip-text {
  color: #0b2432 !important;
}
.our-services-text {
  color: white;
}

@media (max-width: 1000px) {
  .home-follow-us-text {
    font-size: 1.25em;
  }
  .home_page_linkedin_image {
    width: 12%;
    margin-top: 3%;
  }

  .footer-section {
    background: #0b2432 !important;
    font-size: 0.7em !important;
    color: white;
    padding-top: 3%;
    padding-bottom: 3%;
  }
  .tequip-slogan-title {
    color: #0b2432;
    font-size: 1.5em;
    text-align: right;
    margin-bottom: 5%;
  }

  .btn-primary {
    background: #0b2432 !important;
    border-color: #0b2432 !important;
    width: 50%;
    text-decoration: none;
    padding: 0.65rem 1.4rem;
    font-size: 14px;
    opacity: 1;
    color: white !important;
    border-radius: 3px;
  }

  .tequip-slogan-description {
    color: #0b2432;
    font-size: 0.75em;
    text-align: right;
  }

  .home-page-text-container {
    // width: 100%;
    position: absolute;
    top: 90px;
    right: 1px;
    display: flex;
    flex-direction: column;
    text-align: right;
    margin-left: 35%;
    margin-right: 2%;
    animation: 1.5s ease-out 0s 1 slideInFromLeft;
  }

  .why-tequip-description {
    color: #0b2432;
    font-size: 1.15em;
    font-weight: 600;
    padding: 3%;
    text-align: center;
    animation: 1.5s ease-out 0s 1 slideInFromLeft;
  }

  .about-page-tequip-logo {
    width: 30%;
    position: absolute;
    top: 50px;
    right: 100px;
  }

  .our-services-text,
  .why-tequip-text {
    font-size: 1.5em;
  }
}

.our-services-text:after,
.why-tequip-text:after {
  content: "";
  position: absolute;
  left: 50%;
  display: block;
  height: 0.25em;
  width: 6em;
  background: #f8a61a;
  transform: translateX(-50%);
  margin-top: 0.5%;
}

@media (min-width: 1000px) and (max-width: 2000px) {
  .home_page_linkedin_image {
    width: 2%;
    margin-top: 1%;
  }
  .home-follow-us-text {
    font-size: 1.75em;
  }
  .footer-section {
    background: #0b2432 !important;
    font-size: 1.15em !important;
    color: white;
    padding-top: 3%;
    padding-bottom: 3%;
  }
  .about-page-tequip-logo {
    width: 224px;
    position: absolute;
    top: 250px;
    right: 800px;
  }

  .our-services-text,
  .why-tequip-text {
    font-size: 2em;
  }

  .home-page-text-container {
    position: absolute;
    top: 300px;
    right: 150px;
    display: flex;
    flex-direction: column;
    animation: 1.5s ease-out 0s 1 slideInFromLeft;
  }

  .why-tequip-description {
    color: #0b2432;
    font-size: 1.25em;
    font-weight: 600;
    padding: 3%;
    text-align: center;
    animation: 1.5s ease-out 0s 1 slideInFromLeft;
  }

  .btn-primary {
    background: #0b2432 !important;
    border-color: #0b2432 !important;
    width: 50%;
    text-decoration: none;
    padding: 0.65rem 1.4rem;
    font-size: 14px;
    opacity: 1;
    color: white !important;
    border-radius: 3px;
    font-size: 2em;
  }

  .tequip-slogan-title {
    color: #0b2432;
    font-size: 3em;
    text-align: right;
    margin-bottom: 5%;
  }

  .tequip-slogan-description {
    color: #0b2432;
    font-size: 1.5em;
    text-align: right;
  }
}

.why_tequip_image_container {
  display: flex;
  width: 100%;
  justify-content: center;
  // pointer-events: none;
}

.get-started-text-container {
  margin-top: 10%;
  animation: 1.5s ease-out 0s 1 slideInFromLeft;
}

.home-tequip-support-social-media-container {
  display: flex;
  flex-direction: column;
  padding: 10%;
  background-color: #0b2432;
  color: #f8a61a;
}
