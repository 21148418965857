.grid {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0 0 0 -1em;
  direction: inherit;
  text-align: inherit;
}

.feature {
  margin-bottom: 2em;
}

@media screen and (min-width: 62em) {
  .grid__item--3-12-bp3 {
    width: 25%;
  }
}

.grid__item {
  box-sizing: border-box;
  display: grid;
  width: 100% !important;
  margin: 0 -0.25em 0 0;
  text-align: inherit;
  vertical-align: top;
}

.feature__label {
  color: #fed500;
}

.feature__label {
  display: block;
  margin-bottom: 0.25em;
  // font-family: "Montserrat",Corbel,"Lucida Grande","Lucida Sans Unicode","Lucida Sans","DejaVu Sans","Bitstream Vera Sans","Liberation Sans",Verdana,"Verdana Ref",sans-serif;
  font-size: 3rem;
  line-height: 1;
  color: #fff;
}

@media screen and (min-width: 62em) {
  .test--csstransforms3d .section--in-view .feature--1 .feature__icon {
    -webkit-animation-delay: 1000ms;
    -moz-animation-delay: 1000ms;
    -o-animation-delay: 1000ms;
    animation-delay: 1000ms;
  }
}

.feature__text {
  margin-top: 3%;
  font-size: 1.25em;
  color: white;
  font-weight: 500;
}
@media screen and (min-width: 62em) {
  .test--csstransforms3d .section--in-view .feature__icon {
    -webkit-animation-name: pulse;
    -moz-animation-name: pulse;
    -o-animation-name: pulse;
    animation-name: pulse;
    -webkit-animation-duration: 750ms;
    -webkit-animation-fill-mode: both;
    -moz-animation-duration: 750ms;
    -moz-animation-fill-mode: both;
    -o-animation-duration: 750ms;
    -o-animation-fill-mode: both;
    animation-duration: 750ms;
    animation-fill-mode: both;
  }
}

.feature__heading {
  margin-top: 0.5em;
  margin-bottom: 0;
  line-height: 1.125;
}

@media screen and (min-width: 62em) {
  .feature__text {
    font-size: 1rem;
  }
  .feature__text {
    position: relative;
    margin-top: 0.75em;
    padding-top: 1em;
    font-size: 1.063em;
  }
}

@media screen and (min-width: 62em) {
  .test--csstransforms
    .section--in-view
    .our-services-section__content--animated {
    -webkit-animation-name: fadeIn;
    -moz-animation-name: fadeIn;
    -o-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 500ms;
    -webkit-animation-fill-mode: both;
    -moz-animation-duration: 500ms;
    -moz-animation-fill-mode: both;
    -o-animation-duration: 500ms;
    -o-animation-fill-mode: both;
    animation-duration: 500ms;
    animation-fill-mode: both;
    -webkit-animation-delay: 125ms;
    -moz-animation-delay: 125ms;
    -o-animation-delay: 125ms;
    animation-delay: 125ms;
  }
}
@media (max-width: 1000px) {
}
@media (min-width: 1000px) and (max-width: 2000px) {
}
@media screen and (min-width: 62em) {
  .test--csstransforms .our-services-section__content--animated {
    opacity: 0;
  }
}

.feature--yellow .feature__heading,
.feature--red .feature__label {
  color: #f8a61a;
}

.feature__heading {
  color: #f8a61a;
}

.our-services-icon {
  width: 100%;
  pointer-events: none;
}

.our-services-card-container {
  width: 25% !important;
  margin-right: 2%;
  animation: 2s ease-out 0s 1 slideInFromLeft;
}

.our-services-section__content {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
}

.grid__item:active {
  // animation: 0.5s ease-out 0s 1 puff ;
  .our-services-icon {
    opacity: 0.5 !important;
    pointer-events: none;
  }
}

@media (max-width: 1000px) {
  .our-services-card-container {
    animation: 1s ease-out 0s 1 slideInFromLeft;
  }
  .our-services-section__content {
    display: flex;
    flex-flow: wrap;
  }
}
@media (max-width: 1000px) {
  .our-services-card-container {
    width: 60% !important;
  }
}

.our-services-card-description-container {
  position: relative;
  z-index: 3;
  color: white;
  opacity: 0;
  transform: translateY(30px);
  transition: 0.5s;
}
