.careers_tequip_text_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.careers-tequip-text::after {
  content: "";
  position: absolute;
  left: 50%;
  display: block;
  height: 0.25em;
  width: 6em;
  background: #f8a61a;
  transform: translateX(-50%);
  margin-top: 0.8%;
}

@media (max-width: 1000px) {
  .careers-tequip-title {
    margin-top: 10%;
    width: 100%;
    text-align: center;
  }
  .careers-card-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    justify-content: center;
  }
  .careers-tequip-text {
    font-size: 1.5em;
    width: 100%;
  }

  .careers_video {
    width: 70%;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .careers-illstn {
    width: 90%;
    animation: 1.5s ease-out 0s 1 slideInFromLeft;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1%;
    pointer-events: none;
  }
}

@media (min-width: 1000px) and (max-width: 2000px) {
  .careers-tequip-title {
    margin-top: 5%;
    width: 100%;
    text-align: center;
    font-size: 1.75em;
  }
  .careers-card-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    margin-bottom: 10%;
  }

  .careers-tequip-text {
    font-size: 2em;
    width: 100%;
  }

  .careers_video {
    width: 70%;
    height: 500px;
    margin-top: 5%;
  }

  .careers-illstn {
    width: 50%;
    animation: 1.5s ease-out 0s 1 slideInFromLeft;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1%;
    pointer-events: none;
  }
}
.careers-apply-now-container {
  animation: 1.5s ease-out 0s 1 slideInFromLeft;
}