.ind-product-card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.products-section {
    margin-bottom: 10%;
}

.our-productListing-tequip-container {
    margin-top: 5%;
}