.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fw-bold {
  font-weight: bold !important;
}

.text-muted {
  color: #6c757d !important;
}

.small,
small {
  font-size: 13px;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.card {
  border-radius: 65px;
  background-color: #fff;
  -webkit-box-shadow: 2px 6px 15px 0 rgb(69 65 78 / 10%);
  -moz-box-shadow: 2px 6px 15px 0 rgba(69, 65, 78, 0.1);
  box-shadow: 2px 6px 15px 0 rgb(69 65 78 / 10%);
  border: 0;
}

a:link {
  border: none;
  color: #0b2432;
  background-color: transparent;
  text-decoration: none;
}
a:link {
  border: none;
  color: #0b2432;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: white;
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  /* color: #fed500; */
  background-color: transparent;
  text-decoration: underline;
}

.is-hidden {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.section--centered {
  display: table;
  width: 100%;
}
@media screen and (min-width: 48em) {
  .section--large {
    padding: 10em 0 10em 0;
  }
  .section--large {
    padding: 5em 0 5em 0;
  }
}
@media screen and (min-width: 48em) {
  .section {
    padding: 4em 0 4em 0;
  }
  .section {
    padding: 2em 0 2em 0;
  }
}

@media screen and (min-width: 62em) {
  .test--csstransforms
    .section--in-view
    .our-services-section__content--animated {
    -webkit-animation-name: fadeIn;
    -moz-animation-name: fadeIn;
    -o-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 500ms;
    -webkit-animation-fill-mode: both;
    -moz-animation-duration: 500ms;
    -moz-animation-fill-mode: both;
    -o-animation-duration: 500ms;
    -o-animation-fill-mode: both;
    animation-duration: 500ms;
    animation-fill-mode: both;
    -webkit-animation-delay: 125ms;
    -moz-animation-delay: 125ms;
    -o-animation-delay: 125ms;
    animation-delay: 125ms;
  }
}

@media screen and (min-width: 62em) {
  .test--csstransforms .our-services-section__content--animated {
    opacity: 0;
  }
}

/* animation */

@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes puff {
  0% {
    top: 100%;
    height: 0px;
    padding: 0px;
  }
  100% {
    top: 50%;
    height: 100%;
    padding: 0px 100%;
  }
}
@media (min-width: 300px) and (max-width: 1000px) {

  .about_page_linkedin_image {
    width: 10%!important;
  }
  .google_play_badge {
    width: 35%;
    animation: 1.5s ease-out 0s 1 slideInFromLeft;
  }

}
/* 

colors
blue: #0b2432
yellow: #f8a61a

*/
