.news-card-main-container {
  width: 100%;
  height: 100%;
  // margin-right: 5%;
  // margin-left: 10%;
  margin: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-shadow: 0px 0px 10px 10px #d2d2d2;
  border-radius: 100px !important;
  overflow: auto;

  animation: 1.5s ease-out 0s 1 slideInFromLeft;
}

.news-card-main-container:hover {
  cursor: pointer;
  box-shadow: 0px 0px 15px 15px #d2d2d2;
}

.news-card-main-container1:hover {
  cursor: pointer;
  box-shadow: 0px 0px 15px 15px #d2d2d2;
}

.news-card-date {
  font-size: 1em;
  margin-bottom: 3%;
  font-style: italic;
  color: #302f3d !important;
  font-family: "poppins", sans-serif !important;
}

.available-news-card {
  margin-top: 3%;
  width: 45%;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (max-width: 1000px) {
}

@media (min-width: 300px) and (max-width: 400px) {
  .news-card-main-container {
    height: 50% !important;
    border-radius: 30px !important;
    margin: 2 !important;
  }
  .news-card-name {
    font-size: 1em !important;
  }

  .available-news-card {
    width: 50% !important;
    margin-right: none !important;
    margin-top: 0.5% !important;
  }
}

@media (min-width: 375px) and (max-width: 400px) {
  .news-card-main-container {
    text-overflow: ellipsis;
    white-space: nowrap;

    width: 100% !important;
    height: 60% !important;
    border-radius: 30px !important;
    margin: none !important;
  }
}

@media (min-width: 400px) and (max-width: 700px) {
  .news-card-main-container {
    height: 60% !important;
    border-radius: 30px !important;
    margin: none !important;
  }

  .available-news-card {
    width: 45% !important;
    margin-right: 0.11% !important;
    margin-top: 0.5% !important;
  }
}

.card-img-top {
  width: 100%;
}

.card-body pt-2 {
  flex: 1 1 auto;
}
.card .card-body,
.card-light .card-body {
  padding: 0.75em;
}

.image-container {
  width: 60%;
  padding: 0.5rem !important;
}
@media (min-width: 992px) {
  .news-card-main-container {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.rounded {
  border-radius: 0.25rem !important;
}

@media (min-width: 991px) {
  .news-card-main-container1 {
    width: 50% !important;
    animation: 1.5s ease-out 0s 1 slideInFromLeft;
  }
}

@media (max-width: 991px) {
  .news-card-main-container1 {
    animation: 1s ease-out 0s 1 slideInFromLeft;
  }
  .news_platform_text {
    font-size: 1.25em !important;
  }
  .available-news-card {
    margin-top: 15% !important;
    width: 60% !important;
  }
}
