.job-details-container {
  display: flex;
  flex-direction: row;
}
@media (min-width: 300px) and (max-width: 1000px) {
  .footer-section-about {
    background: #f8a61a !important;
    font-size: 0.8em !important;
    color: #0b2432;
    padding-top: 3%;
    padding-bottom: 3%;
  }

  .support-team-container {
    margin-top: 5%;
    margin-left: 5%;
  }

  .job-card-list
    .job-opening-individual-container
    .job-opening-individual-info
    .job-details-desc {
    font-size: 1em;
    color: #7d7b7b;
    margin-right: 1%;
  }

  .job-card-list .job-opening-individual-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    align-items: flex-start;
    width: 100%;
  }

  .job-card-list
    .job-opening-individual-container
    .job-opening-individual-info
    .job-title,
  .job-card-list
    .job-opening-individual-container
    .job-opening-individual-info
    a.job-title {
    color: #f8a61a;
    font-size: 1.15em;
    margin-bottom: 5px;
    font-weight: 600;
  }
}

@media (min-width: 1000px) and (max-width: 2000px) {
  .job-card-list .job-opening-individual-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3%;
    margin: 3%;
    text-align: left;
    align-items: flex-start;
    width: 100%;
    margin-left: 5%;
  }

  .job-card-list
    .job-opening-individual-container
    .job-opening-individual-info
    .job-details-desc {
    font-size: 1.15em;
    color: #7d7b7b;
  }
  .avatar {
    width: 8em;
    height: 8em;
  }

  .job-card-list
    .job-opening-individual-container
    .job-opening-individual-info
    .job-title,
  .job-card-list
    .job-opening-individual-container
    .job-opening-individual-info
    a.job-title {
    color: #f8a61a;
    font-size: 1.75em;
    margin-bottom: 5px;
    font-weight: 600;
  }
}

.job-card-list .job-opening-individual-container .job-opening-individual-info {
  flex: 1;
  width: 100%;
}
