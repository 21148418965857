.not-found-main-container {
  background: #f8a61a;
}

.not-found-tequip-text-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

@media (min-width: 300px) and (max-width: 1000px) {
  .not-found-tequip-text-desc {
    font-size: 1.15em;
    font-weight: 600;
    animation: 1.5s ease-out 0s 1 slideInFromLeft;
    width: 100%;
  }

  .footer-section-not-found {
    font-size: 0.8em !important;
    color: #0b2432;
    position: fixed;
    bottom: 0px;
    width: 100%;
  }
  .not-found-us-illstn {
    margin-left: -17%;
    width: 90%;
    animation: 1.5s ease-out 0s 1 slideInFromLeft;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1%;
    pointer-events: none;
  }

  .take-me-home-btn {
    margin-top: 5%;
    margin-bottom: 80%;
  }
}

@media (min-width: 1000px) and (max-width: 2000px) {
  .take-me-home-btn {
    margin-top: 5%;
  }

  .not-found-tequip-text-desc {
    font-size: 1.75em;
    font-weight: 600;
    animation: 1.5s ease-out 0s 1 slideInFromLeft;
    width: 100%;
  }

  .footer-section-not-found {
    font-size: 1em !important;
    color: #0b2432;
    width: 100%;
    margin-top: 5%;
  }
  .not-found-us-illstn {
    margin-left: -12%;
    width: 50%;
    animation: 1.5s ease-out 0s 1 slideInFromLeft;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1%;
    pointer-events: none;
  }
}
