.tequip-splash-icon {
  width: 100%;
  animation: 1s ease-out 0s 1 fadeInScale;
}

.tequip-splash-container {
  margin-top: 50%;
  display: flex !important;
  justify-content: center !important;
  align-content: center !important;
}

@media only screen and (min-width: 320px) and (max-height: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .tequip-splash-icon {
    margin-top: 40%;
    width: 80%;
    height: 45%;
  }
}
