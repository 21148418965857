.contact-tequip-text:after {
  content: "";
  position: absolute;
  left: 50%;
  display: block;
  height: 0.25em;
  width: 6em;
  background: #f8a61a;
  transform: translateX(-50%);
  margin-top: 0.5%;
}

@media (max-width: 1000px) {
  .contact-email-text {
    margin-top: 5%;
    font-size: 1em;
    animation: 1.5s ease-out 0s 1 slideInFromLeft;
    width: 100%;
    font-style: italic;
  }

  .contact_page_linkedin_image {
    width: 10%;
    margin-top: 3%;
  }
  .btn-contact-primary {
    margin-top: 5%;
    background: #0b2432 !important;
    border-color: #0b2432 !important;
    width: 50%;
    text-decoration: none;
    padding: 0.65rem 1.4rem;
    font-size: 14px;
    opacity: 1;
    color: white !important;
    border-radius: 3px;
  }

  .contact-tequip-text {
    font-size: 1.5em;

    width: 100%;
  }
  .contact-tequip-text-desc {
    margin-top: 5%;
    font-size: 1.15em;
    font-weight: 600;
    animation: 1.5s ease-out 0s 1 slideInFromLeft;
    width: 80%;
  }
  .contact-tequip-support-social-media-container {
    margin-top: 5%;
    font-size: 1.15em;
    font-weight: 600;
    animation: 1.5s ease-out 0s 1 slideInFromLeft;
  }

  .contact-us-illstn,
  .contact-us-google-form-iframe {
    width: 100%;
    animation: 1.5s ease-out 0s 1 slideInFromLeft;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1%;
    pointer-events: none;
  }
  
}

@media (min-width: 1000px) and (max-width: 2000px) {
  .contact-email-text {
    margin-top: 5%;
    font-size: 1.5em;
    animation: 1.5s ease-out 0s 1 slideInFromLeft;
    width: 100%;
    font-style: italic;
  }

  .btn-contact-primary {
    margin-top: 2%;
    background: #0b2432 !important;
    border-color: #0b2432 !important;
    width: 50%;
    text-decoration: none;
    padding: 0.65rem 1.4rem;
    font-size: 14px;
    opacity: 1;
    color: white;
    border-radius: 3px;
    font-size: 2em;
  }

  .contact-tequip-text-desc {
    margin-top: 2%;
    font-size: 1.5em;
    font-weight: 600;
    animation: 1.5s ease-out 0s 1 slideInFromLeft;
    width: 100%;
  }

  .contact-tequip-support-social-media-container {
    margin-top: 5%;
    font-size: 1.5em;
    font-weight: 600;
  }

  .contact_page_linkedin_image {
    width: 10%;
    margin-top: 3%;
  }

  .contact-tequip-text {
    font-size: 2em;
  }

  .contact-us-illstn,
  .contact-us-google-form-iframe {
    width: 100%;
    animation: 1.5s ease-out 0s 1 slideInFromLeft;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1%;
    pointer-events: none;
  }
  .contact-us-illstn {
    width: 40%!important;
  }
}


.contact_tequip_text_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
